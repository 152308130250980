import React, {useEffect} from 'react';
import ReactGA from 'react-ga';

const TableHead = (props) => {

    useEffect(()=>{
        ReactGA.initialize('UA-169600615-2');
    },[])

    const sortingArrowHeight = 13;
    const sortingArrowWidth = 8;

    return(<tr>
        <th></th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Out of Five'
                });
                if(props.sortingFactor === 'out_of_five'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('out_of_five');
                }
            }
        }>
            WhichVPN Score&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'out_of_five' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'out_of_five' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Streaming Score'
                });
                if(props.sortingFactor === 'streaming_score'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('streaming_score');
                }
            }
        }>
            Streaming Score&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'streaming_score' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'streaming_score' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Torrenting Score'
                });
                if(props.sortingFactor === 'torrenting_score'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('torrenting_score');
                }
            }
        }>
            Torrenting Score&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'torrenting_score' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'torrenting_score' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Devices'
                });
                if(props.sortingFactor === 'devices'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('devices');
                }
            }
        }>
            Max Devices&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'devices' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'devices' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Monthly Price'
                });
                if(props.sortingFactor === 'price_monthly'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('price_monthly');
                }
            }
        }>
            Price Per Month (1 Month)&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'price_monthly' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'price_monthly' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Annual Price'
                });
                if(props.sortingFactor === 'best_price_twelve_month'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('best_price_twelve_month');
                }
            }
        }>
            12 Month Contract&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'best_price_twelve_month' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'best_price_twelve_month' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'OpenVPN Encryption?'
                });
                if(props.sortingFactor === 'encryption_protocols_supported'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('encryption_protocols_supported');
                }
            }
        }>
            Open VPN Encryption&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'encryption_protocols_supported' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'encryption_protocols_supported' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Speed Score'
                });
                if(props.sortingFactor === 'speed_score'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('speed_score');
                }
            }
        }>
            Speed Score&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'speed_score' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'speed_score' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Countries'
                });
                if(props.sortingFactor === 'countries'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('countries');
                }
            }
        }>
            Countries&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'countries' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'countries' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Servers'
                });
                if(props.sortingFactor === 'servers'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('servers');
                }
            }
        }>
            Servers&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'servers' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'servers' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Registered Country Score'
                });
                if(props.sortingFactor === 'registered_country_score'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('registered_country_score');
                }
            }
        }>
            5 Eyes Country Score
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'registered_country_score' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'registered_country_score' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th> 
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Anonymous Payment'
                });
                if(props.sortingFactor === 'anonymous_payment'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('anonymous_payment');
                }
            }
        }>
            Anonymous Payment?&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'anonymous_payment' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'anonymous_payment' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th> 
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Audited Score'
                });
                if(props.sortingFactor === 'audited_score'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('audited_score');
                }
            }
        }>
            Independently Audited?&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'audited_score' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'audited_score' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        <th onClick={
            ()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sort Table',
                    label : 'Ethical Marketing Score'
                });
                if(props.sortingFactor === 'ethical_marketing_score'){
                    if(props.sortA === -1){
                        props.setSortA(1);
                        props.setSortB(-1)
                    }
                    else{
                        props.setSortA(-1);
                        props.setSortB(1);
                    }
                }
                else{
                    props.setSortingFactor('ethical_marketing_score');
                }
            }
        }>
            Ethical Marketing&nbsp;
            <svg width={sortingArrowWidth} height={sortingArrowHeight} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 0L7.4641 5.25H0.535898L4 0Z" fill={props.sortingFactor === 'ethical_marketing_score' && props.sortA === 1 ? "#000000" : "#DADADA"}/>
                <path d="M4 13L0.535898 7.75L7.4641 7.75L4 13Z" fill={props.sortingFactor === 'ethical_marketing_score' && props.sortA === -1 ? "#000000" : "#DADADA"}/>
            </svg>
        </th>
        
        <th>
            {/* Need to be here for sign up box */}
        </th>
    </tr>);
}

export default TableHead;