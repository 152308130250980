import React, { useState, useEffect } from 'react';
import vpns from './vpns3.json';
// import vpns2 from './vpns2.json';
import Modal from 'react-modal';
// import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import TableHead from './TableHead';
import Table from './Table';
import DetailModal from './DetailModal';
import DocumentMeta from 'react-document-meta';


const Compare = () => {

    const requestUSD = axios.get("https://api.exchangeratesapi.io/v1/latest?access_key=ddafcfd9e1f11f6ec3b54b4b5b1ca0e3&base=USD");
    const requestEUR = axios.get("https://api.exchangeratesapi.io/v1/latest?access_key=ddafcfd9e1f11f6ec3b54b4b5b1ca0e3&base=EUR");
    const requestGBP = axios.get("https://api.exchangeratesapi.io/v1/latest?access_key=ddafcfd9e1f11f6ec3b54b4b5b1ca0e3&base=GBP");

    const meta = {
        title : "VPN Comparison Table Search Select Filter Criteria WhichVPN",
        description : "A proper VPN comparison table with simple and advanced criteria to search and select on, clear, open, honest information, including prices and easy to use.",
        meta : {
            charset : "utf-8",
            name : {
                keywords : "VPN, Compare, Virtal, Private, Network, Cheap, Best, Safe, Secure, Cyber, Security"
            }
        }
    };

    useEffect(() => {
        
        ReactGA.initialize('UA-169600615-2');
        ReactGA.pageview('/compare');

        axios.all([requestUSD, requestEUR, requestGBP]).then(axios.spread((...responses) => {
            const responseUSD = responses[0];
            const responseEUR = responses[1];
            const responseGBP = responses[2];

            for (var x = 0; x < vpns.length; x++) {
                if (vpns[x].currency === "Pounds") {
                    vpns[x].convertedPrices = {
                        monthly: {
                            eur: (vpns[x].price_monthly * responseGBP.data.rates.EUR),
                            usd: (vpns[x].price_monthly * responseGBP.data.rates.USD),
                            gbp: vpns[x].price_monthly,
                        },
                        annual: {
                            eur: (vpns[x].best_price_twelve_month * responseGBP.data.rates.EUR),
                            usd: (vpns[x].best_price_twelve_month * responseGBP.data.rates.USD),
                            gbp: (vpns[x].best_price_twelve_month),
                        }
                    }
                }
                else if (vpns[x].currency === "Euros") {
                    vpns[x].convertedPrices = {
                        monthly: {
                            eur: vpns[x].price_monthly,
                            usd: (vpns[x].price_monthly * responseEUR.data.rates.USD),
                            gbp: (vpns[x].price_monthly * responseEUR.data.rates.GBP),
                        },
                        annual: {
                            eur: (vpns[x].best_price_twelve_month),
                            usd: (vpns[x].best_price_twelve_month * responseEUR.data.rates.USD),
                            gbp: (vpns[x].best_price_twelve_month * responseEUR.data.rates.GBP),
                        }
                    }
                }
                else if (vpns[x].currency === "Dollars") {
                    vpns[x].convertedPrices = {
                        monthly: {
                            eur: (vpns[x].price_monthly * responseUSD.data.rates.EUR),
                            usd: vpns[x].price_monthly,
                            gbp: (vpns[x].price_monthly * responseUSD.data.rates.GBP),
                        },
                        annual: {
                            eur: (vpns[x].best_price_twelve_month * responseUSD.data.rates.EUR),
                            usd: (vpns[x].best_price_twelve_month),
                            gbp: (vpns[x].best_price_twelve_month * responseUSD.data.rates.GBP),
                        }
                    }
                }
            }

            setReady(true);
        })).catch(errors => {
            // react on errors.
        });
    }, []);
    const [sortingFactor, setSortingFactor] = useState('out_of_five');
    const [selectedVPN, setSelectedVPN] = useState(vpns[0]);
    const [filters, setFilters] = useState({
        torrenting: 0,
        streaming: 0,
        devices: 0,
        budget: {
            currency: "gbp",
            input: 15,
            frequency: "pm",
            amount: 15
        },
        registered: 0,
        anonymity: 0,
        audited: 0,
        ethical: 0
    });
    const [advanced, setAdvanced] = useState(false);
    const [sortA, setSortA] = useState(1);
    const [sortB, setSortB] = useState(-1);
    const [ready, setReady] = useState(false);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            minWidth: '600px',
            maxWidth: '750px',
            maxHeight: '90%',
            boxShadow: '0px 0px 12px rgb(150,150,150)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px'
        }
    };

    Modal.setAppElement('#app');

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }


    return (
        <DocumentMeta {...meta}>
            <div className="configuration-container">

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Full VPN Details"
                >
                    <DetailModal closeModal={() => { closeModal() }} selectedVPN={selectedVPN} />
                </Modal>

                <h2>Configure</h2>

                <div className="input-container">
                    <label htmlFor="">Streaming Score</label>
                    <select className="select-input"
                        value={filters.streaming}
                        onChange={(e) => setFilters({
                            ...filters,
                            streaming: Number(e.target.value)
                        })}>
                        <option value="0">Don't Mind</option>
                        <option value="3">3 (List Good only)</option>
                        <option value="2">2 (List Acceptable or better)</option>
                        <option value="1">1 (List All)</option>
                    </select>
                </div>

                <div className="input-container">
                    <label htmlFor="">Torrenting Score</label>
                    <select className="select-input"
                        value={filters.torrenting}
                        onChange={(e) => setFilters({
                            ...filters,
                            torrenting: Number(e.target.value)
                        })}>
                        <option value="0">Don't Mind</option>
                        <option value="3">3 (List Good only)</option>
                        <option value="2">2 (List Acceptable or better)</option>
                        <option value="1">1 (List All)</option>
                    </select>
                </div>

                <div className="input-container">
                    <label htmlFor="">Max Devices</label>
                    <select className="select-input"
                        value={filters.devices}
                        onChange={(e) => setFilters({
                            ...filters,
                            devices: Number(e.target.value)
                        })}>
                        <option value="0">Don't Mind</option>
                        <option value="1">1+</option>
                        <option value="2">2+</option>
                        <option value="3">3+</option>
                        <option value="5">5+</option>
                        <option value="6">6+</option>
                        <option value="7">7+</option>
                        <option value="10">10+</option>
                        <option value="12">12+</option>
                        <option value="99">99+</option>
                    </select>
                </div>

                <div className="input-container budget-input">
                    <label htmlFor="">Budget</label>
                    <div className="budget-input-wrapper">
                        <select className="select-input currency-input"
                            value={filters.budget.currency}
                            onChange={(e) => setFilters({
                                ...filters,
                                budget: {
                                    ...filters.budget,
                                    currency: e.target.value
                                }
                            })}>
                            <option value="usd">&#36;</option>
                            <option value="gbp">&#163;</option>
                            <option value="eur">&#8364;</option>
                        </select>
                        <input type="number"
                            className="text-input"
                            value={filters.budget.input}
                            onChange={(e) => {
                                setFilters({
                                    ...filters,
                                    budget: {
                                        ...filters.budget,
                                        input: e.target.value,
                                        amount: e.target.value
                                    }
                                })
                            }} />
                        <select className="select-input frequency-input"
                            value={filters.budget.frequency}
                            onChange={(e) => setFilters({
                                ...filters,
                                budget: {
                                    ...filters.budget,
                                    frequency: e.target.value
                                }
                            })}>
                            <option value="pm">per month (1 month)</option>
                            <option value="py">per month (12 months)</option>
                        </select>
                    </div>
                </div>
                <div className="learn-scores">
                        <a href="https://blog.whichvpn.com/2021/01/20/whichvpn-how-we-score/" target="_blank">
                            Learn about how we score
                        </a>
                    </div>
                <div>
                    <div className="advanced-button" onClick={() => { setAdvanced(!advanced) }}>
                        <h3>Advanced </h3><div className={`arrow-wrapper ${advanced ? 'rotated-arrow' : ''} `}>&#8594;</div>
                    </div>
                    <div className={`advanced-inputs ${advanced ? "visible" : ""}`}>
                        <div className="input-container">
                            <label htmlFor="">5 Eyes Country Score</label>
                            <select className="select-input"
                                value={filters.registered}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    registered: Number(e.target.value)
                                })}>
                                <option value="0">Don't Mind</option>
                                <option value="3">3 (Outside of 5/9/14 Eyes)</option>
                                <option value="2">2 (Outside of 5 Eyes)</option>
                                <option value="1">1 (Inside 5 Eyes)</option>
                            </select>
                            {/* <Link className="learn-5-eyes">Learn more about what 5 eyes means </Link> */}
                        </div>

                        <div className="input-container">
                            <label htmlFor="">Can You Pay Anonymously?</label>
                            <select className="select-input"
                                value={filters.anonymity}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    anonymity: Number(e.target.value)
                                })}>
                                <option value="0">Don't Mind</option>
                                <option value="3">Yes</option>
                                <option value="1">No</option>
                            </select>
                        </div>

                        <div className="input-container">
                            <label htmlFor="">Independently Audited?</label>
                            <select className="select-input"
                                value={filters.audited}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    audited: Number(e.target.value)
                                })}>
                                <option value="0">Don't Mind</option>
                                <option value="3">3 (Yes with acknowledged 3rd Party)</option>
                                <option value="2">2 (No, but seems highly regarded)</option>
                                <option value="1">1 (No)</option>
                            </select>
                        </div>

                        <div className="input-container">
                            <label htmlFor="">Ethical Marketing</label>
                            <select className="select-input"
                                value={filters.ethical}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    ethical: Number(e.target.value)
                                })}>
                                <option value="0">Don't Mind</option>
                                <option value="3">3 (No evidence of any misleading marketing)</option>
                                <option value="2">2 (Allows some misleading marketing)</option>
                                <option value="1">1 (Supports misleading marketing)</option>
                            </select>
                        </div>
                    </div>
                    <p className="disclaimer">Remember: we are an affiliate content provider, we may occasionally make money from your subscription, but the providers have zero input on our scores</p>
                </div>
            </div>
            <div className="table-wrapper">
                {/* <p>Remember: we are an affiliate content provider, we may occasionally make money from your subscription, but the providers have Zero input on our reviews</p> */}
                <div className="table-container">
                    <table className="all-vpns">
                        <thead>
                            <TableHead
                                sortA={sortA}
                                sortB={sortB}
                                setSortA={(val) => setSortA(val)}
                                setSortB={(val) => setSortB(val)}
                                sortingFactor={sortingFactor}
                                setSortingFactor={(val) => setSortingFactor(val)}
                            />
                        </thead>
                        {ready ? <Table vpns={vpns} filters={filters} sortA={sortA} sortB={sortB} sortingFactor={sortingFactor} openModal={()=>openModal()} setSelectedVPN={(vpn) => setSelectedVPN(vpn)}/> : <tbody><tr><td>Loading Data</td></tr></tbody>}

                        
                    </table>
                </div>
            </div>
        </DocumentMeta>
    );
}
export default Compare;