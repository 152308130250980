/* External Libraries */
import React from 'react';

// Below lines got deprecated and the export const history = createHistory line
// import createHistory from 'history/createBrowserHistory';
import {createBrowserHistory} from 'history';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/* Internal Libraries */
// import Login from '../components/login';
import Homepage from '../components/homepage/Homepage';
import Header from '../components/header/Header';
import About from '../components/about/About';
import Compare from '../components/table/Compare';
import PrivacyPolicy from '../components/docs/PrivacyPolicy';
import Affiliate from '../components/docs/Affiliate';
import Footer from '../components/footer/Footer';

export const history = createBrowserHistory();
// export const history = createHistory();

const AppRouter = () => (
  <Router history={history}>
	<div>  
    <Header/>
    <Switch>
      <Route exact path="/" component={Homepage}/>
      <Route path="/compare" component={Compare}/>
      <Route path="/about" component={About}/>
      <Route path="/privacy-policy" component={PrivacyPolicy}/>
      <Route path="/affiliate-disclosure" component={Affiliate}/>
      {/* <Route path="/my-stores" component={MyStoreMain}/> */}
    </Switch>
	  <Footer/>
    </div>
  </Router>
);

export default AppRouter;
