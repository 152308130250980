import React from 'react';

const Tooltip = (props) => {
    return(
        <div className="tooltip-wrapper">
            <div className="tooltip-marker">
            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="info">
                        <circle id="Oval" fill="#F2F2F2" cx="9" cy="9" r="9"></circle>
                        <path d="M9,0 C4.03748438,0 0,4.03748438 0,9 C0,13.9625156 4.03748438,18 9,18 C13.9625156,18 18,13.9625156 18,9 C18,4.03748438 13.9625156,0 9,0 Z M9,17.2500117 C4.45092188,17.2500117 0.749988281,13.5490781 0.749988281,9 C0.749988281,4.45092188 4.45092188,0.749988281 9,0.749988281 C13.5490781,0.749988281 17.2500117,4.45092188 17.2500117,9 C17.2500117,13.5490781 13.5490781,17.2500117 9,17.2500117 Z" id="Shape" fill="#DDDDDD" fillRule="nonzero"></path>
                        <path d="M8.314,10.348 C8.041,10.049 7.885,9.659 7.885,9.204 C7.885,7.657 9.783,7.475 9.783,6.578 C9.783,6.175 9.484,5.837 8.769,5.837 C8.119,5.837 7.573,6.162 7.17,6.643 L6.117,5.46 C6.806,4.654 7.846,4.199 8.964,4.199 C10.641,4.199 11.668,5.044 11.668,6.253 C11.668,8.151 9.497,8.294 9.497,9.386 C9.497,9.594 9.601,9.815 9.731,9.932 L8.314,10.348 Z M8.938,13.143 C8.366,13.143 7.885,12.662 7.885,12.09 C7.885,11.518 8.366,11.037 8.938,11.037 C9.51,11.037 9.991,11.518 9.991,12.09 C9.991,12.662 9.51,13.143 8.938,13.143 Z" id="?" fill="#737373" fillRule="nonzero"></path>
                    </g>
                </g>
            </svg>
            </div>
            <div className="tooltip-placer">
                <span className="tooltip">{props.content}</span>
            </div>
        </div>
    )
}

export default Tooltip;