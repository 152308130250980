import React, {useState} from 'react';
import TableRow from './TableRow';

const Table = (props) => {

const setSelectedVPN = (vpn) => {
    props.setSelectedVPN(vpn);
}

const openParentModal = () => {
    props.openModal();
} 


var translatePrices = (vpn) => {
        // console.log(vpn);
        if(props.filters.budget.frequency === "pm"){
            if(props.filters.budget.currency === "gbp"){
                return vpn.convertedPrices.monthly.gbp
            }
            else if(props.filters.budget.currency === "usd"){
                return vpn.convertedPrices.monthly.usd
            }
            else if(props.filters.budget.currency === "eur"){
                return vpn.convertedPrices.monthly.eur
            }
        }
        else if(props.filters.budget.frequency === "py"){
            if(props.filters.budget.currency === "gbp"){
                return vpn.convertedPrices.annual.gbp
            }
            else if(props.filters.budget.currency === "usd"){
                return vpn.convertedPrices.annual.usd
            }
            else if(props.filters.budget.currency === "eur"){
                return vpn.convertedPrices.annual.eur
            }
        }
    }




    const listItems = props.vpns.filter(vpn => vpn.streaming_score >= props.filters.streaming && vpn.torrenting_score >= props.filters.torrenting && vpn.devices >= props.filters.devices && vpn.registered_country_score >= props.filters.registered && vpn.anonymous_payment >= props.filters.anonymity && vpn.audited_score >= props.filters.audited && vpn.ethical_marketing_score >= props.filters.ethical && props.filters.budget.amount >= translatePrices(vpn)).sort((a, b) => a[props.sortingFactor] < b[props.sortingFactor] ? props.sortA : props.sortB).map((vpn, index) => <TableRow key={index} vpn={vpn} selectedCurrency={props.filters.budget.currency} openModal={props.openModal} setSelectedVPN={setSelectedVPN} />); 

    // <TableRow vpn={vpn} openModal={openModal} selectedCurrency={props.filters.budget.currency} setSelectedVPN={(vpn) => { setSelectedVPN(vpn) }} />

    return(
        <tbody>
            {listItems} 
        </tbody>
    );
}

export default Table;