import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const Header = () => {

    const [showMobileNav, setShowMobileNav] = useState("hide");

    const toggleMenu = () => {
        if(showMobileNav === "hide"){
            setShowMobileNav("show");
        }
        else{
            setShowMobileNav("hide");
        }
    }

    return(
        <header>
            <Link to='/'>
                <img src="./images/logo.svg" alt=""/>
            </Link>
            <nav>
                <ul>
                    <li>
                        <Link to='/about'>
                            About
                        </Link> 
                    </li>
                    <li>
                        <a href='https://blog.whichvpn.com'>
                            Blog
                        </a>
                    </li>
                    <li>
                        <Link to='/compare' id="header-compare">
                            Compare
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="mobile-nav-button">
                <button onClick={toggleMenu}>
                    Menu
                </button>
            </div>
            <div className={`mobile-nav ${showMobileNav}`}>
                <div className="mobile-logo-cancel">
                    <Link to='/'>
                        <img src="./images/logo.svg" className="mobile-nav-logo" width="120" alt=""/>
                    </Link>
                    <img onClick={toggleMenu} className="cancel" src="./images/crossblack.svg" alt=""/>
                </div>
                <ul>
                    <li className="mobile-nav-item">
                        <Link  onClick={toggleMenu} to="/about">
                            About
                        </Link>
                    </li>
                    <li  onClick={toggleMenu} className="mobile-nav-item">
                        <a href="https://blog.whichvpn.com">
                            Blog
                        </a>
                    </li>
                    <li className="mobile-nav-item">
                        <Link onClick={toggleMenu} to="/compare">
                            Compare
                        </Link>
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default Header;