import React, {useEffect} from 'react';
import ReactGA from 'react-ga';


const TableRow = (props) => {

    useEffect(()=>{
        ReactGA.initialize('UA-169600615-2');
    },[])

    return(<tr onClick={
        ()=>{
            ReactGA.event({
                category: 'Comparison Table Interaction',
                action: 'More Detail Modal',
                label : `${props.vpn.name}`
            });
            props.setSelectedVPN(props.vpn);
            props.openModal();
        }
    }>
        <th>
            {props.vpn.name}
        </th>
        <td>
            {props.vpn.out_of_five}
        </td>
        <td>
            {
                props.vpn.streaming_score === 1 ? <div className="score-circle ones">
                    <span>1</span>
                </div> : 
                    props.vpn.streaming_score === 2 ? <div className="score-circle twos">
                    <span>2</span>
                </div> : 
                <div className="score-circle threes">
                    <span>3</span>
                </div>     
            }
        </td>
        <td>
            {
                props.vpn.torrenting_score === 1 ? <div className="score-circle ones">
                    <span>1</span>
                </div> : 
                    props.vpn.torrenting_score === 2 ? <div className="score-circle twos">
                    <span>2</span>
                </div> : 
                <div className="score-circle threes">
                    <span>3</span>
                </div>     
            }
        </td>
        <td>
            {props.vpn.devices}
        </td>
        <td>
            {
                props.vpn.price_monthly === "" ? `N/A` :  
                props.selectedCurrency === "usd" ? `$${(props.vpn.convertedPrices.monthly.usd).toFixed(2)}` : props.selectedCurrency === "eur" ? `€${(props.vpn.convertedPrices.monthly.eur).toFixed(2)}` : `£${(props.vpn.convertedPrices.monthly.gbp).toFixed(2)}`
            }
            
        </td>
        <td>
            {props.selectedCurrency === "usd" ? `$${(props.vpn.convertedPrices.annual.usd * 12).toFixed(2)}` : props.selectedCurrency === "eur" ? `€${(props.vpn.convertedPrices.annual.eur * 12).toFixed(2)}` : `£${(props.vpn.convertedPrices.annual.gbp * 12).toFixed(2)}`}<br/>
            <span className="annual-extra">({props.selectedCurrency === "usd" ? `$${(props.vpn.convertedPrices.annual.usd).toFixed(2)}` : props.selectedCurrency === "eur" ? `€${(props.vpn.convertedPrices.annual.eur).toFixed(2)}` : `£${(props.vpn.convertedPrices.annual.gbp).toFixed(2)}`}/Per Month)</span>
            
        </td>
        <td> 
            {
                props.vpn.encryption_protocols_supported === "OpenVPN" ? 
                <img src="./images/check.svg" width="22"/> 
                : 
                <img src="./images/cross.svg" width="22"/>       
            }
        </td>
        <td>
            {
                props.vpn.speed_score === 1 ? <div className="score-circle ones">
                    <span>1</span>
                </div> : 
                    props.vpn.speed_score === 2 ? <div className="score-circle twos">
                    <span>2</span>
                </div> : 
                <div className="score-circle threes">
                    <span>3</span>
                </div>     
            }
        </td>
        <td>
            {props.vpn.countries}
        </td>
        <td>
            {props.vpn.servers}
        </td>
        <td>
            {
                props.vpn.registered_country_score === 1 ? <div className="score-circle ones">
                    <span>1</span>
                </div> : 
                    props.vpn.registered_country_score === 2 ? <div className="score-circle twos">
                    <span>2</span>
                </div> : 
                <div className="score-circle threes">
                    <span>3</span>
                </div>     
            }
        </td>
        <td>
            {
                props.vpn.anonymous_payment === 1 ? <img src="./images/cross.svg" width="22"/> 
                : 
                props.vpn.anonymous_payment === 3 ? <img src="./images/check.svg" width="22"/> 
                : null 
            }
        </td>
        <td>
            {
                props.vpn.audited_score === 1 ? <div className="score-circle ones">
                    <span>1</span>
                </div> : 
                    props.vpn.audited_score === 2 ? <div className="score-circle twos">
                    <span>2</span>
                </div> : 
                <div className="score-circle threes">
                    <span>3</span>
                </div>     
            }
        </td>
        <td>
            {
                props.vpn.ethical_marketing_score === 1 ? <div className="score-circle ones">
                    <span>1</span>
                </div> : 
                    props.vpn.ethical_marketing_score === 2 ? <div className="score-circle twos">
                    <span>2</span>
                </div> : 
                <div className="score-circle threes">
                    <span>3</span>
                </div>     
            }
        </td>
        <td>
            <a onClick={()=>{
                ReactGA.event({
                    category: 'Comparison Table Interaction',
                    action: 'Sign Up',
                    label : `${props.vpn.name}`
                });
            }} href={props.vpn.affiliate_link === "" ? props.vpn.standard_link : props.vpn.affiliate_link} className="cta">
                <span>Sign Up</span>
            </a>
        </td>
    </tr>);
}

export default TableRow;