import React from 'react';
import Tooltip from './Tooltip';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-169600615-2');

const DetailModal = (props) => {

    return(
    <div className="modal-content">

        <div className="modal-headline">
            <div className="modal-headline-content-wrapper">
            <div className="modal-logo-wrapper">
                <img src={`/images/logos/${props.selectedVPN.logo}`} alt="vpn-logo"/> 
            </div> 
            <div className="modal-headline-score">
                <h2>{props.selectedVPN.out_of_five}<span>&nbsp;/&nbsp;5</span></h2>
            </div> 
            </div>
        </div>

        <div className="table-pad">
            <table className="vpn-detail-table">
                <tbody>
                    <tr>
                        <th>
                            Name
                        </th>
                        <td>
                            {props.selectedVPN.name}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Monthly Price (1 Month)
                            <Tooltip content="This is the price when you have a 1 month contract"/>    
                        </th>
                        <td>
                            {
                                props.selectedVPN.currency === "Pounds" ? '£'
                                :
                                props.selectedVPN.currency === "Dollars" ? `$`
                                : 
                                `€`}{Number(props.selectedVPN.price_monthly).toFixed(2)
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Monthly Price (12 Months)
                            <Tooltip content="This is under a 12 month contract. It will be a lump sum and this value is the lump sum divided by 12"/>
                        </th>
                        <td>
                            {props.selectedVPN.currency === "Pounds" ? '£'
                            :
                            props.selectedVPN.currency === "Dollars" ? `$`
                            : 
                            `€`}{props.selectedVPN.best_price_twelve_month.toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <th>Native Currency</th>
                        <td>{props.selectedVPN.currency}</td>
                    </tr>
                    <tr>
                        <th>Streaming Score</th>
                        <td>{props.selectedVPN.streaming_score}</td>
                    </tr>
                    {
                        props.selectedVPN.streaming_comment ?
                        <tr>
                            <th>Streaming Notes</th>
                            <td>{props.selectedVPN.streaming_comment}</td>
                        </tr>
                        : null
                    }
                    {
                        props.selectedVPN.streaming_channels ?
                        <tr>
                            <th>Streaming Notes</th>
                            <td>{props.selectedVPN.streaming_channels}</td>
                        </tr>
                        : null
                    }
                    <tr>
                        <th>Torrenting Score</th>
                        <td>{props.selectedVPN.torrenting_score}</td>
                    </tr>
                    {
                        props.selectedVPN.torrenting_comment ?
                        <tr>
                            <th>Torrenting Comment</th>
                            <td>{props.selectedVPN.torrenting_comment}</td>
                        </tr>
                        : null
                    }
                    <tr>
                        <th>Speed Score</th>
                        <td>{props.selectedVPN.speed_score}</td>
                    </tr>
                    {
                        props.selectedVPN.speed_comment ?
                        <tr>
                            <th>Speed Notes</th>
                            <td>{props.selectedVPN.speed_comment}</td>
                        </tr>
                        : null
                    }
                    <tr>
                        <th>
                            Max Devices
                            <Tooltip content="This is the most amount of devices that can be running concurrently through the VPN"/>
                        </th>
                        <td>{props.selectedVPN.devices}</td>
                    </tr>
                    {
                        props.selectedVPN.devices_comment ?
                        <tr>
                            <th>Devices Notes</th>
                            <td>{props.selectedVPN.devices_comment}</td>
                        </tr>
                        : null
                    }
                    <tr>
                        <th>
                            Independently Audited?
                            <Tooltip content="Have they been independently audited by a recognised 3rd party to prove they keep no logs."/>    
                        </th>
                        <td>
                            {props.selectedVPN.audited_score}
                            {props.selectedVPN.audited_score === 1 ?
                                " - (No)"
                                :
                                props.selectedVPN.audited_score === 2 ? " - (No, but seems highly regarded)"
                                    :
                                    props.selectedVPN.audited_score === 3 ? " - (Yes with acknowledged 3rd Party)"
                                        : null
                            }
                        </td>
                    </tr>
                    {
                        props.selectedVPN.audited_score_source_info ?
                            <tr>
                                <th>
                                    Audited Score Comment
                                </th>
                                <td>{props.selectedVPN.audited_score_source_info}</td>
                            </tr>
                            : null
                    }
                    <tr>
                        <th>Support Option</th>
                        <td>{props.selectedVPN.support_option}</td>
                    </tr>
                    {
                        props.selectedVPN.support_option_comments ?
                            <tr>
                                <th>
                                    Support Comments
                                            </th>
                                <td>{props.selectedVPN.support_option_comments}</td>
                            </tr>
                            : null
                    }
                    <tr>
                        <th>Reputation Country Score</th>
                        <td>{props.selectedVPN.reputation_score}</td>
                    </tr>
                    {
                        props.selectedVPN.reputation_comment ?
                            <tr>
                                <th>Reputation Comment</th>
                                <td>{props.selectedVPN.reputation_comment}</td>
                            </tr>
                            : null
                    }
                    <tr>
                        <th>Encryption Protocols</th>
                        <td>{props.selectedVPN.encryption_protocols_supported}</td>
                    </tr>
                    {
                        props.selectedVPN.encryption_protocol_comment ?
                            <tr>
                                <th>Encryption Protocol Comment</th>
                                <td>{props.selectedVPN.encryption_protocol_comment}</td>
                            </tr>
                            : null
                    }
                    <tr>
                        <th>Can You Pay Anonymously?</th>
                        <td>{props.selectedVPN.anonymous_payment}</td>
                    </tr>
                    <tr>
                        <th>Best Price Twelve Months</th>
                        <td>{props.selectedVPN.best_price_twelve_month}</td>
                    </tr>
                    <tr>
                        <th>5 Eyes Country Score</th>
                        <td>
                            {props.selectedVPN.registered_country_score}
                            {props.selectedVPN.registered_country_score === 1 ?
                                " - (Inside 5 Eyes)"
                                :
                                props.selectedVPN.registered_country_score === 2 ? " - (Outside of 5 Eyes)"
                                    :
                                    props.selectedVPN.registered_country_score === 3 ? " - (Outside of 5/9/14 Eyes)"
                                        : null
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>Country Registered</th>
                        <td>{props.selectedVPN.registered_country}</td>
                    </tr>
                    <tr>
                        <th>Combined Review Score</th>
                        <td>{props.selectedVPN.combined_review_score}</td>
                    </tr>
                    <tr>
                        <th>Countries</th>
                        <td>{props.selectedVPN.countries}</td>
                    </tr>
                    <tr>
                        <th>Servers</th>
                        <td>{props.selectedVPN.servers}</td>
                    </tr>
                    <tr>
                        <th>
                            Ethical Marketing Score
                            <Tooltip content="How misleading is their advertising and marketing (against FTC regulations in the US and ASA regulations in the UK)."/>
                        </th>

                        <td>
                            {props.selectedVPN.ethical_marketing_score}
                            {props.selectedVPN.ethical_marketing_score === 1 ?
                                " - (Heavy use of misleading Popups/Clickbait/'Free')"
                                :
                                props.selectedVPN.ethical_marketing_score === 2 ? " - (Uses some misleading marketing)"
                                    :
                                    props.selectedVPN.ethical_marketing_score === 3 ? " - (No evidence of any misleading marketing)"
                                        : null
                            }
                        </td>
                    </tr>
                    {
                        props.selectedVPN.ethical_marketing_comments ?
                            <tr>
                                <th>Ethical Marketing Comments</th>
                                <td>{props.selectedVPN.ethical_marketing_comments}</td>
                            </tr>
                            : null
                    }
                    <tr>
                        <th>Money Back</th>
                        <td>{props.selectedVPN.money_back}</td>
                    </tr>
                    <tr>
                        <th>Free comment</th>
                        <td>{props.selectedVPN.free_comment}</td>
                    </tr>
                    {
                        props.selectedVPN.price_note ?
                            <tr>
                                <th>Price Note</th>
                                <td>{props.selectedVPN.price_note}</td>
                            </tr>
                            : null
                    }
                </tbody>
            </table>

            <div className="app-store-ratings">
                <h5 className="ratings-table-title">App Reviews ({props.selectedVPN.combined_review_score} Avg)</h5>
                <div className="image-wrapper">
                    <img src="./images/app-store.svg" height="46" alt=""/>   
                </div>
                <div className="image-wrapper">
                    <img src="./images/play-store.svg" height="46" alt=""/>
                </div>
                <div className="image-wrapper">
                    <img src="./images/trustpilot.svg" width="125" alt=""/>
                </div>
                <div className="image-wrapper">
                    <img src="./images/amazon.svg" width="80" alt=""/>
                </div>
                <span>{props.selectedVPN.app_store_score ? props.selectedVPN.app_store_score : 'N/A'}</span>
                <span>{props.selectedVPN.play_store_score ? props.selectedVPN.play_store_score : 'N/A'}</span>  
                <span>{props.selectedVPN.trustpilot_score ? props.selectedVPN.trustpilot_score : 'N/A'}</span>
                <span>{props.selectedVPN.amazon_store_score ? props.selectedVPN.amazon_store_score : 'N/A'}</span>
            </div>

            {/* <table className="ratings-table">
                <thead>
                    <tr>
                        <td colSpan="4">
                            
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            <img src="./images/app-store.svg" height="46" alt=""/>
                        </th>
                        <th>
                            <img src="./images/play-store.svg" height="46" alt=""/>
                        </th>
                        <th>
                            <img src="./images/trustpilot.svg" width="125" alt=""/>
                        </th>
                        <th>
                            <img src="./images/amazon.svg" width="80" alt=""/>
                        </th>
                    </tr>
                    <tr>
                        <td>{props.selectedVPN.app_store_score ? props.selectedVPN.app_store_score : 'N/A'}</td>
                        <td>{props.selectedVPN.play_store_score ? props.selectedVPN.play_store_score : 'N/A'}</td>
                        <td>{props.selectedVPN.trustpilot_score ? props.selectedVPN.trustpilot_score : 'N/A'}</td>
                        <td>{props.selectedVPN.amazon_store_score ? props.selectedVPN.amazon_store_score : 'N/A'}</td>
                    </tr>
                </tbody>
            </table> */}
            </div>
            <div className="vpn-detail-cta-wrapper">
                <button
                    onClick={props.closeModal}
                    className="cancel"
                >Back</button>
                <a onClick={()=>{
                    ReactGA.event({
                        category: 'VPN More Detail',
                        action: 'Sign Up',
                        label : `${props.selectedVPN.name}`
                    });
                }} href={props.selectedVPN.affiliate_link === "" ? props.selectedVPN.standard_link : props.selectedVPN.affiliate_link} className="vpn-detail-sign-up" target="_blank"> 
                    Sign Up <img width="14" src="/images/icons/external.svg" />
                </a>
                <p>Remember: we are an affiliate content provider, we may occasionally make money from your subscription, but the providers have zero input on our scores</p>
            </div>
        

        
    </div>);
}

export default DetailModal;