import React from 'react';
import DocumentMeta from 'react-document-meta';
const Affiliate = () => {

    const meta = {
        title : "VPN Affiliate Disclosure Proud Honest Open True WhichVPN",
        description : "A VPN affiliate disclosure to explain who we are and what being an affiliate means to us, what we stand for and what we don't, open, honest, true.",
        meta : {
            charset : "utf-8",
            name : {
                keywords : "VPN, Compare, Virtal, Private, Network, Cheap, Best, Safe, Secure, Cyber, Security"
            }
        }
    };

    return(
        <DocumentMeta {...meta}>
        <div className="documentation" >
            <h1>Affiliate Disclosure</h1>
            <p>
                We are a professional comparison website. We may receive compensation from the VPN Publishers in our table if you click on a link and then purchase their product. Not all the VPNs we list have an affiliate program, and we have not joined all that we can.
            </p>

            <p>
                We do not participate in any other form of advertising or promotion. You will not find adverts, banners, popups, “independent” reviews, tests, top 10’s (remember, it is theirs not yours) or the like on our website, and we don't use clickbait to drive traffic to our website - yes this all happens on many leading VPN Publishers (and VPN Vendors) websites.
            </p>

            <p>
                The data we provide is compiled after careful research from a large number of sources including the VPN’s own website, news web sites, review websites, social media websites etc. to enable us to come to a considered view and opinion of the VPNs in order to score them and add appropriate comments (our own will be indicated).
            </p>
            <p>
                Note: We are aware of the overwhelming number of VPN Publishers that may not have appropriate declarations (i.e. they are affiliates masquerading as news websites or privacy websites) and/or maybe heavily invested (in more ways than one) to specific VPN Providers and who may provide reviews and tests of variable thoroughness and quality. Also that review websites (e.g. Trustpilot) can and have been manipulated by VPN Vendors to try and fool you. Note, this comment could equally apply to the affiliate industry as a whole and to any product you are considering buying and are Googling for "un-biased, independent advice and reviews" - good luck and buyer beware, it’s a jungle out there!
            </p>

            <p>
                We are not perfect; we will make mistakes. It is possible to be hoodwinked by the odd VPN Publisher (or VPN Provider) but we try hard to identify and counter this by the large number of different sources we use in our research. The trend is our friend is a phrase to help explain our data collection and scoring technique.
            </p>

            <p>
                We are as open and honest as we can be and declare information to be as transparent as possible - all feedback is gratefully received and will be considered.
            </p>
        </div>
        </DocumentMeta>
    );
}

export default Affiliate;