import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';

const About = () => {

    const meta = {
        title : "VPN Why We Are Different And Why We are Needed WhichVPN",
        description : "Why there is a need for a propoer VPN comparison website and some revealing insites into what the 'competition' are up to, its like taking the red pill.",
        meta : {
            charset : "utf-8",
            name : {
                keywords : "VPN, Compare, Virtal, Private, Network, Cheap, Best, Safe, Secure, Cyber, Security"
            }
        }
    };


    useEffect(()=>{
        ReactGA.initialize('UA-169600615-2');
        ReactGA.pageview('/about');
    },[])

    return(
        <DocumentMeta {...meta}>
        <div className="about">
            <h2 className="u-title">About Us</h2>

            <h3>Why was the website created?</h3>
            <p>WhichVPN? was created to fill a gap in the market for a proper VPN comparison website. Currently trying to find which VPN to purchase is like trying to navigate a minefield. Searches generally result in pages of publishers providing fixed lists of their favourite VPNs, and that’s just the start.</p>

            <ul>
                <li>
                    <h4>A free VPN?</h4>
                    <p>You can ignore the countless “Free VPN” or “Really Free VPN” results, some of which are actually advertisements (against any and all regulations) as none are actually free, but that is what clickbait is about, you click on it first and then comes the disappointment - but the publishers still earn enough to continue the practice unfortunately.</p>
                </li>
                <li>
                    <h4>All affiliates</h4>
                    <p>They are all affiliates of course, but a large percentage of them don’t disclose it - although according to the regulations (FTC in the US and ASA in the UK) you should make an obvious declaration before you start recommending products or reading any reviews which have links in them.</p>
                </li>
                <li>
                    <h4>“Fixed” lists</h4>
                    <p>These publishers and their fixed lists do not have all the information you need to compare, have no functionality to actually compare them, and have scoring systems that are far from transparent - leading you down a pre-determined path to buy their favourite VPN.</p>
                </li>
                <li>
                    <h4>Popups</h4>
                    <p>No sooner are you on one of these sites than pop-ups appear to entice you to select their favourite VPN - popups that are misleading - according to any regulation ( FTC in the US, ASA in the UK, and Google’s own advertising guidelines). Similar popups have been banned, but the rewards are too enticing for many to ignore.</p>
                </li>
                <li>
                    <h4>Obscurity</h4>
                    <p>Whilst there are many VPN reviews provided by publishers, many seem to have a very cosy relationship with the Vendors, key information and insights do not seem to gain the prominence they deserve, and pricing is often obscure - surely you want to know it all?</p>
                </li>
            </ul>

            <h3>WhichVPN? are different</h3>
            <p>We wanted to create a comparison site like you would expect a comparison site to work - enter the criteria you were interested in and the system would help prioritise the results with easy to use filters and sorting. Clear, comparable, transparent, honest, unbiased  information, free from misleading popups and clickbait.</p>

            <h3>WhichVPN? list any VPN</h3>
            <p>You may notice some of the VPNs we have on our comparison site are not generally found on the VPN Publishers “fixed” lists - that’s because we list VPNs whether they have an affiliate program or not!</p>


            <h3>Blogs that don’t hold back</h3>

            <p>We are not afraid to publish the cold hard truth - of VPN Publishers, VPN Vendors, tricks and tips that will help you choose your ideal VPN, with some surprises and revelations for sure - so make sure you sign up to read it first, before if appears on our website for all to see!</p>

            <h3>Why not try it out, it’s free?</h3>

            <p>It’s easy to use, help yourself and see what you think. All feedback gratefully received, good or bad.</p>

            <h3>Disclosure</h3>
            
            <p>WhichVPN? are an affiliate publisher, so we earn commission if you click on some of our links and purchase a VPN. We are proud to be one of a new generation of affiliate publishers who are open and honest, driven by service, rather that sales. We have no baggage to carry, no large office and overheads to pay for, no pressure to make sales - which leaves us to be able to focus on the service we provide to you, WhichVPN? are in it for the long term, the tortoise that beats the hare, the David that slays Goliath.</p>
        </div>
        </DocumentMeta>
    );
}

export default About;