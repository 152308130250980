import React, {useState, useEffect} from 'react';
import {decode} from 'html-entities';
import {Link} from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';

const Homepage = () => {

    const [blogFeed, setBlogFeed] = useState([]);
    const [blogPosts, setBlogPosts] = useState('');

    const meta = {
        title : "VPN Comparison Website Unbiased Provider Selection WhichVPN",
        description : "Welcome to a real VPN comparison website with filters and sorting to help you select your ideal VPN with unbiased honest information and revealing blogs",
        meta : {
            charset : "utf-8",
            name : {
                keywords : "VPN, Compare, Virtal, Private, Network, Cheap, Best, Safe, Secure, Cyber, Security"
            }
        }
    }
    

    useEffect(() => {
        
        ReactGA.initialize('UA-169600615-2');
        axios.get('https://blog.whichvpn.com/wp-json/wp/v2/posts').then((response) => {
            //Set max of 3 items
            setBlogFeed(response.data.slice(0,3));
            // console.log(response);
        });
    }, []);

    useEffect(() => {
        if (blogFeed !== []) {
            setBlogPosts(blogFeed.map((blog, index) => <a href={blog.link} key={index}><div className="tile" style={{backgroundImage:`url(${blog.jetpack_featured_media_url})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className="tile-title">
                <h4 className="p-title">
                    {decode(blog.title.rendered)}
                    {/* {blog.title.rendered} */}
                </h4>
            </div>
        </div></a>));
        }
    }, [blogFeed]);
   
    return(
        <DocumentMeta {...meta}>
            <div className="hero">
                <div className="content-wrapper">
                    <h1 className="u-title">Navigating the world of VPNs is hard.</h1>
                    <h2 className="u-title">We're here to change that.</h2>

                    <Link className="cta white" to='/compare'>
                        Compare Now
                    </Link>
                </div>
                
                <img className="hero-image" src="/images/hero-logo.png" alt=""/>

                <div className="custom-shape-divider-bottom-1597429298">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>

            <div className="intro">
                <div className="content-wrapper">
                    <div className="intro-content">
                        <div className="intro-text">
                            <h3 className="u-title">WhichVPN is a proud affiliate publisher</h3>

                            <p>
                                Why are we proud? Because first and foremost we have developed a website with the aim of truly and honestly helping you find your ideal VPN. This is what a VPN affiliate publisher should be aiming and striving for, but unfortunately many fall short. 
                            </p>
                            <p>
                                We appreciate the potential contradiction of both being an affiliate where we are paid if someone clicks on our links, and providing unbiased information to enable you to make a completely free choice. We can only do that by being very open, very honest, walking the talk and considering your feedback.
                            </p>
                        </div>
                        <div className="intro-image">
                            <img srcSet="/images/lock-computer.png 1x,
                                /images/lock-computer@2x.png 2x" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-sample">
                <div className="content-wrapper">
                    <h3 className="u-title">Looking to learn about VPNs?</h3>

                    <div className="tile-holder">
                        {blogPosts}
                    </div>
                </div>
            </div>

            <div className="contact">
                <div className="content-wrapper">
                    <div className="contact-content">
                        <div className="contact-image">
                        <img srcSet="/images/contact-icon.png 1x,
                                /images/contact-icon@2x.png 2x" alt=""/>
                        </div>
                        <div className="contact-text">
                            <h3 className="u-title">Get in touch</h3>

                            <div className="input-wrapper contact-input">
                                {/* <label htmlFor="email">Email</label>
                                <input 
                                    name="email"
                                    type="text"
                                    className="email-input" 
                                    placeholder="your@email.com"/>
                                <button>Submit</button> */}
                                <p>info@whichvpn.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}

export default Homepage;