import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
    return(
        <footer>
            <div className="content-wrapper">
                <Link to="/">
                    <img src="/images/logo-white.svg" alt=""/>
                </Link>

                <div className="footer-info">
                    <div>
                        <p>
                            Company Number: 12785055
                        </p>
                        <p>
                            25 Barbourne Road, Worcester, England, WR1 1RU
                        </p>
                    </div>
                    <div className="footer-navigation">
                        <ul>
                            <Link to="/">
                                <li>Home</li>
                            </Link>
                            <Link to="/about">
                                <li>About Us</li>
                            </Link>
                            <a href='https://blog.whichvpn.com'>
                               <li>Blog</li> 
                            </a>
                            <Link to="/compare">
                                <li>Compare</li>
                            </Link>
                        </ul>
                    </div>
                    <div className="official-docs">
                        <ul>
                            {/* <li>
                                Cookie Policy
                            </li> */}
                            <li>
                                <Link to="/affiliate-disclosure">
                                    Affiliate Disclosure
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                Copyright © 2021, 2020 WhichVPN Limited    
                            </li>

                        </ul>
                    </div>
                    {/* <div className="social-links">
                        <img src="/images/linkedin.svg" alt=""/>
                        <img src="/images/twitter.svg" alt=""/>
                        <img src="/images/youtube.svg" alt=""/>
                    </div> */}
                </div> 
            </div>
        </footer>
    );
};

export default Footer;